<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title" v-if="!seeTH">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="doCancel()">合同管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{
            this.$route.query.stu == "edit1"
              ? "修改合同"
              : this.$route.query.stu == "add"
                ? "新增合同"
                : "查看合同"
          }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h4>基本信息</h4>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules"
              :disabled="this.$route.query.stu == 'look' || seeTH" label-width="11rem">
              <el-form-item label="合同编号" class="form-item">
                <el-input size="small" v-model="ruleForm.contractCode" type="text" placeholder="请输入合同编号" disabled />
              </el-form-item>
              <el-form-item label="合同类型" prop="contractType" class="form-item">
                <el-select placeholder="请选择合同类型" clearable size="small" v-model="ruleForm.contractType"
                  @change="contractChange" :disabled="seeTH">
                  <el-option v-for="item in contractTypeList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="合同名称" prop="contractName" class="form-item">
                <el-input size="small" v-model="ruleForm.contractName" type="text" placeholder="请输入合同名称" maxlength="50"
                  show-word-limit clearable :disabled="seeTH" />
              </el-form-item>
              <el-form-item v-if="ruleForm.contractType == '40'" label="代理类型" prop="agencyType" class="form-item">
                <el-select placeholder="请选择代理类型" clearable size="small" v-model="ruleForm.agencyType" :disabled="seeTH">
                  <el-option v-for="item in agentTypeList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关联代理商" prop="agencyId" class="form-item formSelect"
                v-if="ruleForm.contractType == '40'">
                <div style="display: flex">
                  <div style="flex: 1">
                    <el-select size="small" v-model="ruleForm.agencyId" remote :remote-method="getCompanyAgentList"
                      filterable clearable placeholder="请输入代理商名称或个人代理商联系人姓名，至少输入两个字搜索" :disabled="seeTH">
                      <el-option v-for="item in CompanyAgentList" :key="item.agencyId" :label="item.compName"
                        :value="item.agencyId"></el-option>
                    </el-select>
                  </div>
                  <div style="margin-left: 10px" v-show="!seeTH">
                    <el-button class="bgc-bv" size="small" @click="handleCreate('add', 'contract')">新增代理商</el-button>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="签订机构" prop="signCompId" class="form-item" v-if="ruleForm.contractType != '40'">
                <el-select size="small" v-model="ruleForm.signCompId" remote :remote-method="getCompanyList" filterable
                  clearable placeholder="请至少输入两个字搜索" :disabled="seeTH">
                  <div slot="empty" style="
                          margin: 10px 0;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        ">
                    <el-button class="bgc-bv" size="small" @click="addCompany">新增机构</el-button>
                  </div>
                  <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName" :value="item.compId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="实际培训单位" prop="compIds" class="form-item" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                stu == 'edit1'
              ">
                <el-select size="small" v-model="ruleForm.compIds" multiple remote :remote-method="getCompanyList"
                  filterable clearable placeholder="请至少输入两个字搜索" :disabled="seeTH">
                  <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                    :value="item.compId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="签订日期" prop="signDate" class="form-item">
                <el-date-picker v-model="ruleForm.signDate" type="date" size="small" clearable placeholder="选择日期"
                  format="yyyy-MM-dd" value-format="yyyy-MM-dd" :disabled="seeTH">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="有效期" prop="termValidity" class="form-item">
                <el-date-picker v-model="ruleForm.termValidity" type="daterange" size="small" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  :disabled="seeTH">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="甲方联系人" prop="partyAName" class="form-item">
                <el-input size="small" v-model="ruleForm.partyAName" type="text" maxlength="10" show-word-limit
                  placeholder="请输入甲方联系人" clearable :disabled="seeTH" />
              </el-form-item>
              <el-form-item label="甲方联系方式" prop="partyAMobile" class="form-item">
                <el-input size="small" v-model="ruleForm.partyAMobile" type="text" placeholder="请输入甲方联系方式" clearable
                  :disabled="seeTH" />
              </el-form-item>
              <el-form-item label="决策人" prop="decisionMaker" class="form-item" v-if="ruleForm.contractType == '10'">
                <el-input size="small" v-model="ruleForm.decisionMaker" type="text" placeholder="请输入决策人" clearable
                  :disabled="seeTH" />
              </el-form-item>
              <el-form-item label="决策人联系方式" prop="decisionMakerMobile" class="form-item"
                v-if="ruleForm.contractType == '10'">
                <el-input size="small" v-model="ruleForm.decisionMakerMobile" type="text" placeholder="请输入决策人联系方式"
                  clearable maxlength="50" show-word-limit :disabled="seeTH" />
              </el-form-item>
              <el-form-item label="乙方联系人" prop="partyBName" class="form-item">
                <el-input size="small" v-model="ruleForm.partyBName" type="text" maxlength="10" show-word-limit
                  placeholder="请输入乙方联系人" clearable :disabled="seeTH" />
              </el-form-item>
              <el-form-item label="乙方联系方式" prop="partyBMobile" class="form-item">
                <el-input size="small" v-model="ruleForm.partyBMobile" type="text" placeholder="请输入乙方联系方式" clearable
                  :disabled="seeTH" />
              </el-form-item>
              <el-form-item label="乙方开户银行" prop="partyBBank" class="form-item" v-if="
                ruleForm.contractType == '20' || ruleForm.contractType == '40'
              ">
                <el-input size="small" maxlength="150" show-word-limit v-model="ruleForm.partyBBank" type="text"
                  placeholder="请输入乙方开户银行" clearable :disabled="seeTH" />
              </el-form-item>
              <el-form-item label="乙方银行账号" prop="partyBAccount" class="form-item" v-if="
                ruleForm.contractType == '20' || ruleForm.contractType == '40'
              ">
                <el-input size="small" v-model="ruleForm.partyBAccount" maxlength="150" show-word-limit type="text"
                  placeholder="请输入乙方银行账号" clearable :disabled="seeTH" />
              </el-form-item>
              <!-- <el-form-item label="签字人" prop="signatory" class="form-item">
                <el-input
                  size="small"
                  v-model="ruleForm.signatory"
                  type="text"
                  maxlength="10"
                  show-word-limit
                  placeholder="请输入签字人"
                  clearable
                  :disabled="seeTH"
                />
              </el-form-item> -->
              <el-form-item label="合同对接业务员" prop="saleId" class="form-item">
                <el-select size="small" v-model="ruleForm.saleId" clearable filterable :disabled="seeTH"
                  @change="saleChange" placeholder="请选择对接业务员">
                  <el-option v-for="item in salesmanList" :key="item.saleId" :label="item.saleName"
                    :value="item.saleId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客户性质" prop="customerNature" class="form-item">
                <el-radio-group v-model="ruleForm.customerNature" :disabled="seeTH">
                  <el-radio :label="item.value" :key="index" v-for="(item, index) in customerNatureList">{{
                    item.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="this.$route.query.stu != 'add' && ruleForm.turnOverSaleName" label="最新负责人"
                prop="turnOverSaleName" class="form-item">
                <el-input placeholder="" v-model="ruleForm.turnOverSaleName" size="small" disabled />
              </el-form-item>
              <el-form-item label="代理商名称" prop="agencyId2" class="form-item formContractId"
                v-if="ruleForm.contractType == '10' || ruleForm.contractType == '30'">
                <el-select size="small" v-model="ruleForm.agencyId2" remote :remote-method="getAgencyName"
                  @change="$forceUpdate()" :disabled="seeTH" filterable clearable placeholder="请输入要查询的代理商">
                  <el-option v-for="item in agencyNameList" :key="item.agencyId" :label="item.compName"
                    :value="item.agencyId"></el-option>
                </el-select>
                <el-button size="mini" class="bgc-bv" @click="bindJG" style="margin-left: 10px"
                  v-if="ruleForm.agencyId2 && ruleForm.signCompId"
                  :disabled="seeTH || $route.query.stu == 'look'">绑定机构</el-button>
              </el-form-item>

              <!--20230323-->
              <el-form-item label="所属行政区划" prop="areaId">
                <el-cascader clearable filterable v-model="ruleForm.areaId" :options="areatreeList" :props="propsarea"
                  size="small" :disabled="seeTH"></el-cascader>
              </el-form-item>
              <!--20230323-->
              <el-form-item label="培训类型" prop="trainTypeIdRange" class="form-item" v-if="ruleForm.contractType == '10'">
                <el-select size="small" v-model="ruleForm.trainTypeIdRange" clearable filterable multiple
                  :disabled="seeTH" placeholder="请选择培训类型">
                  <el-option v-for="item in trainingTypeList" :key="item.id" :label="item.label"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关联合同" prop="contractId" class="form-item formContractId"
                v-if="ruleForm.contractType != '40'">
                <el-select size="small" v-model="ruleForm.contractId" remote :remote-method="getTargetList"
                  :disabled="seeTH" filterable clearable placeholder="请至少输入两个字搜索">
                  <el-option v-for="item in targetList" :key="item.contractId" :label="item.contractName"
                    :value="item.contractId"></el-option>
                </el-select>
                <el-button size="mini" class="bgc-bv" @click="getTarget" style="margin-left: 10px"
                  :disabled="seeTH || $route.query.stu == 'look'">查找</el-button>
              </el-form-item>
              <h4>结算信息</h4>
              <el-form-item label="收款时间约定" prop="collectionTimeAgreement" class="form-item"
                v-if="ruleForm.contractType == '10'">
                <el-radio-group v-model="ruleForm.collectionTimeAgreement" :disabled="seeTH">
                  <el-radio :label="item.value" :key="index" v-for="(item, index) in collectionTimeAgreementList">{{
                    item.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="结算方式" prop="settlementMethod" class="form-item" v-if="ruleForm.contractType == '10'">
                <el-radio-group v-model="ruleForm.settlementMethod" :disabled="seeTH">
                  <el-radio :label="item.value" v-for="(item, index) in settlementMethodList" :key="index">{{ item.label
                    }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="结算标准" prop="settleStandardType" class="form-item"
                v-if="ruleForm.contractType == '20'">
                <el-radio-group v-model="ruleForm.settleStandardType" @change="settleStandardTypeChange"
                  :disabled="seeTH">
                  <el-radio label="10">按国家补贴标准比例</el-radio>
                  <el-radio label="20">按人数收费</el-radio>
                  <el-radio label="30">固定费用</el-radio>
                  <el-radio label="40">其他</el-radio>
                  <el-radio label="50">按课时</el-radio>
                  <el-radio label="60">按实收比例</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-else-if="
                ruleForm.contractType == '10' || ruleForm.contractType == '30'
              " label="结算标准" prop="settleStandardType" class="form-item">
                <el-radio-group v-model="ruleForm.settleStandardType" @change="settleStandardTypeChange"
                  :disabled="seeTH">
                  <el-radio label="10">技术/课程费用分开</el-radio>
                  <el-radio label="20">技术/课程费用合计</el-radio>
                  <el-radio label="30">固定费用</el-radio>
                  <el-radio label="40">其他</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-else label="结算标准" prop="settleStandardType" class="form-item">
                <el-radio-group class="dlradio" v-model="ruleForm.settleStandardType" @change="settleStandardTypeChange"
                  :disabled="seeTH">
                  <el-radio label="10">按比例付费</el-radio>
                  <el-radio label="20">按人数扣费</el-radio>
                  <el-radio label="30">按人数付费</el-radio>
                  <el-radio label="40">按课时付费</el-radio>
                  <el-radio label="50">按付款时间扣费</el-radio>
                  <el-radio label="60">按人次阶梯扣费</el-radio>
                  <el-radio label="70">按课时扣费</el-radio>
                  <el-radio label="80">按补贴人数扣费</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 代理商合同新增的结算标准 -->
              <template v-if="ruleForm.contractType == '40'">
                <!-- 代理商 - 按比例付费 -->
                <template v-if="ruleForm.settleStandardType == '10'">
                  <el-form-item prop="proportion" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <el-input placeholder="" v-model="ruleForm.proportion" size="small" :disabled="seeTH">
                          <template slot="append">%</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <!-- 代理商 - 按人数扣费 -->
                <template v-if="ruleForm.settleStandardType == '20'">
                  <el-form-item prop="peopleNumfeeDeduction" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 3rem">每人:</span>
                        <el-input placeholder="" v-model="ruleForm.peopleNumfeeDeduction" size="small"
                          :disabled="seeTH">
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <!-- 代理商 - 按人数付费 -->
                <template v-if="ruleForm.settleStandardType == '30'">
                  <el-form-item prop="peopleNumPay" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 3rem">每人:</span>
                        <el-input placeholder="" v-model="ruleForm.peopleNumPay" size="small" :disabled="seeTH">
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <!-- 代理商 - 按课时付费 -->
                <template v-if="ruleForm.settleStandardType == '40'">
                  <el-form-item prop="classHourPay" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <el-input placeholder="" v-model="ruleForm.classHourPay" size="small" :disabled="seeTH">
                          <template slot="append">元/人/课时</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <!-- 代理商 - 按付款时间扣费 -->
                <template v-if="ruleForm.settleStandardType == '50'">
                  <el-form-item prop="prepayment" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 4rem">预付费:</span>
                        <el-input placeholder="" v-model="ruleForm.prepayment" size="small" :disabled="seeTH">
                          <template slot="append">元/人</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item prop="afterPaying" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 4rem">后付费:</span>
                        <el-input placeholder="" v-model="ruleForm.afterPaying" size="small" :disabled="seeTH">
                          <template slot="append">元/人</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <!-- 代理商 - 按人次阶梯扣费 -->
                <template v-if="ruleForm.settleStandardType == '60'">
                  <div v-for="(item, index) in ruleForm.personTimeList" :key="index">
                    <el-form-item class="form-item-inline" :prop="'personTimeList.' + index + '.startNum'" :rules="{
                      required: true, message: '人次阶段不能为空', trigger: 'blur'
                    }">
                      <el-input-number placeholder="" :controls="false" :min="1" :precision="0"
                        @blur="compareTheSize(index, 'min')" v-model="item.startNum" size="small"
                        :disabled="index === 0 || seeTH"></el-input-number>&ensp;&ensp;~&ensp;&ensp;
                    </el-form-item>
                    <el-form-item class="form-item-inline left" :prop="'personTimeList.' + index + '.endNum'" :rules="{
                      required: true, message: '人次阶段不能为空', trigger: 'blur'
                    }">
                      <el-input-number placeholder="" :controls="false" :min="1" :precision="0"
                        @blur="compareTheSize(index, 'max')" v-model="item.endNum" size="small"
                        :disabled="seeTH"></el-input-number>&ensp;人&ensp;&ensp;&ensp;
                    </el-form-item>
                    <el-form-item class="form-item-inline left" :prop="'personTimeList.' + index + '.price'" :rules="{
                      required: true, message: '费用不能为空', trigger: 'blur'
                    }">
                      <el-input placeholder="" v-model="item.price" size="small"
                        :disabled="seeTH"></el-input>&ensp;元/人&ensp;&ensp;&ensp;
                      <el-button :disabled="index === 0 || $route.query.stu == 'look' || seeTH" size="small"
                        @click.prevent="removeItem(index)">删除</el-button>
                      <el-button v-if="index === 0" :disabled="seeTH" type="primary" size="small"
                        @click.prevent="addItem()">新增</el-button>
                    </el-form-item>
                  </div>
                </template>
                <!-- 代理商 - 按课时扣费 -->
                <template v-if="ruleForm.settleStandardType == '70'">
                  <el-form-item prop="classHourfeeDeduction" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <el-input placeholder="" v-model="ruleForm.classHourfeeDeduction" size="small"
                          :disabled="seeTH">
                          <template slot="append">元/人/课时</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <!-- 代理商 - 按补贴人数扣费 -->
                <template v-if="ruleForm.settleStandardType == '80'">
                  <el-form-item prop="subsidiesNumfeeDeduction" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 4rem">已领补贴:</span>
                        <el-input placeholder="" v-model="ruleForm.subsidiesNumfeeDeduction" size="small"
                          :disabled="seeTH">
                          <template slot="append">元/人</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item prop="unSubsidiesNumfeeDeduction" class="form-item">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 4rem">未领补贴:</span>
                        <el-input placeholder="" v-model="ruleForm.unSubsidiesNumfeeDeduction" size="small"
                          :disabled="seeTH">
                          <template slot="append">元/人</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </template>
              </template>
              <el-form-item prop="charge" class="form-item" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                (ruleForm.settleStandardType == '10' ||
                  ruleForm.settleStandardType == '20')
              ">
                <el-radio-group v-model="ruleForm.charge" @change="settleStandardTypeChange" :disabled="seeTH">
                  <el-radio label="10">按比例收费</el-radio>
                  <el-radio label="20">按人数收费</el-radio>
                  <el-radio label="30">按课时</el-radio>
                  <el-radio label="40" v-if="ruleForm.settleStandardType == '20'">按次结算</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 分开 -- 比例收费 -->
              <div style="display: flex; flex-direction: column" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                ruleForm.settleStandardType == '10' &&
                ruleForm.charge == '10'
              ">
                <div style="display: flex">
                  <el-form-item prop="technologyRatio" class="form-item" v-if="
                    ruleForm.contractType != '20' &&
                    ruleForm.contractType != '40' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '10'
                  ">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 4rem">技术比例:</span>
                        <el-input placeholder="" v-model="ruleForm.technologyRatio" size="small" :disabled="seeTH">
                          <template slot="append">%</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item prop="coursesRatio" class="form-item left" v-if="
                    ruleForm.contractType != '20' &&
                    ruleForm.contractType != '40' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '10'
                  ">
                    <div style="
                            display: flex;
                            margin-left: 10px;
                            align-items: center;
                          ">
                      <span style="min-width: 4rem">课程比例:</span>
                      <el-input placeholder="" v-model="ruleForm.coursesRatio" size="small" :disabled="seeTH">
                        <template slot="append">%</template>
                      </el-input>
                    </div>
                  </el-form-item>
                </div>
                <p style="margin-left: 11rem">
                  计算公式:技术费用=补贴标准*技术比例*人数
                  <span>课程费用=补贴标准*课程比例*人数</span>
                </p>
              </div>
              <!-- 合计 -- 比例收费 -->
              <el-form-item prop="ratio" class="form-item" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                ruleForm.settleStandardType == '20' &&
                ruleForm.charge == '10'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">比例:</span>
                    <el-input placeholder="" v-model="ruleForm.ratio" size="small" :disabled="seeTH">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:平台服务费=补贴标准*比例*人数</p>
              </el-form-item>
              <!-- 分开 -- 人数收费 -->
              <div style="display: flex; flex-direction: column" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                ruleForm.settleStandardType == '10' &&
                ruleForm.charge == '20'
              ">
                <div style="display: flex">
                  <el-form-item prop="technologyCharge" class="form-item" v-if="
                    ruleForm.contractType != '20' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '20'
                  ">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 6rem">技术每人收费:</span>
                        <el-input placeholder="" v-model="ruleForm.technologyCharge" size="small" :disabled="seeTH">
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item prop="coursesCharge" class="form-item left" v-if="
                    ruleForm.contractType != '20' &&
                    ruleForm.contractType != '40' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '20'
                  ">
                    <div style="display: flex">
                      <div style="
                              display: flex;
                              margin-left: 10px;
                              align-items: center;
                            ">
                        <span style="min-width: 6rem">课程每人收费:</span>
                        <el-input placeholder="" v-model="ruleForm.coursesCharge" size="small" :disabled="seeTH">
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <p style="margin-left: 11rem">
                  计算公式:技术费用=技术每人收费*人数
                  <span>课程费用=课程每人收费*人数</span>
                </p>
              </div>
              <!-- 合计 -- 人数收费 -->
              <template v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                ruleForm.settleStandardType == '20' &&
                ruleForm.charge == '20'
              ">
                <el-form-item prop="chargePerson" class="form-item">
                  <div style="display: flex">
                    <div style="display: flex; align-items: center">
                      <span style="min-width: 9rem">领取补贴每人收费:</span>
                      <el-input placeholder="" v-model="ruleForm.chargePerson" size="small" :disabled="seeTH">
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item prop="chargeUnPerson" class="form-item">
                  <div style="display: flex">
                    <div style="display: flex; align-items: center">
                      <span style="min-width: 9rem">未领取补贴每人收费:</span>
                      <el-input placeholder="" v-model="ruleForm.chargeUnPerson" size="small" :disabled="seeTH">
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <p>计算公式:平台服务费=领取补贴每人收费*人数+未领取补贴每人收费*人数</p>
                </el-form-item>
              </template>
              <!-- 分开 -- 课时收费 -->
              <div style="display: flex; flex-direction: column" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                ruleForm.settleStandardType == '10' &&
                ruleForm.charge == '30'
              ">
                <div style="display: flex">
                  <el-form-item prop="technologyChargePerson" class="form-item" v-if="
                    ruleForm.contractType != '20' &&
                    ruleForm.contractType != '40' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '30'
                  ">
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <span style="min-width: 8rem">技术每人/课时收费:</span>
                        <el-input placeholder="" v-model="ruleForm.technologyChargePerson" size="small"
                          :disabled="seeTH">
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item prop="coursesChargePerson" class="form-item left" v-if="
                    ruleForm.contractType != '20' &&
                    ruleForm.contractType != '40' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '30'
                  ">
                    <div style="display: flex">
                      <div style="
                              display: flex;
                              margin-left: 10px;
                              align-items: center;
                            ">
                        <span style="min-width: 8rem">课程每人/课时收费:</span>
                        <el-input placeholder="" v-model="ruleForm.coursesChargePerson" size="small" :disabled="seeTH">
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <p style="margin-left: 11rem">
                  计算公式:技术费用=技术每人/课时收费*人数*课时
                  <span>课程费用=课程每人/课时*人数*课时</span>
                </p>
              </div>
              <!-- 合计 -- 课时收费 -->
              <el-form-item prop="chargeHourPerson" class="form-item" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                ruleForm.settleStandardType == '20' &&
                ruleForm.charge == '30'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 8rem">每人/课时收费:</span>
                    <el-input placeholder="" v-model="ruleForm.chargeHourPerson" size="small" :disabled="seeTH">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:平台服务费=每人/课时收费*人数*课时</p>
              </el-form-item>
              <!-- 合计 -- 按次数收费 -->
              <el-form-item class="form-item" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                ruleForm.settleStandardType == '20' &&
                ruleForm.charge == '40'
              ">
                <div class="df card-border form-box" v-for="(item, index) in SettlementByTimeList" :key="index">
                  <el-form :model="item" ref="cardform" label-width="7rem" class="form form-bg"
                    style="margin-bottom:20px">
                    <el-form-item :label="'第' + (Number(index) + 1) + '次'" prop="SettlementByTime" :rules="[
                      {
                        required: true,
                        trigger: 'blur',
                        message: '请输入第' + (Number(index) + 1) + '次结算金额',
                      },
                    ]">
                      <el-input placeholder="" v-model="item.SettlementByTime" size="small" :disabled="seeTH">
                        <template slot="append">元/人</template>
                      </el-input>
                    </el-form-item>
                  </el-form>
                  <div class="zsBtn" style="padding-left:15px">
                    <el-button v-if="SettlementByTimeList.length > 1" @click="delCertificate(index)" type="text"
                      :disabled="item.disabled">删除</el-button>
                    <el-button v-if="
                      index == SettlementByTimeList.length - 1 && index != 9
                    " @click="certificate" type="text">添加</el-button>
                  </div>
                </div>
                <p>计算公式:平台服务费=人数*费用</p>
              </el-form-item>
              <!-- 固定费用  -->
              <el-form-item prop="fixedCost" class="form-item" v-if="
                ruleForm.contractType != '20' &&
                ruleForm.contractType != '40' &&
                ruleForm.settleStandardType == '30'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">固定费用:</span>
                    <el-input placeholder="" v-model="ruleForm.fixedCost" size="small" :disabled="seeTH">
                      <template slot="append">元/年</template>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
              <!-- 付款合同 --比例收费 -->
              <el-form-item prop="fkcoursesRatio" class="form-item" v-if="
                ruleForm.contractType == '20' &&
                ruleForm.settleStandardType == '10'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">课程比例:</span>
                    <el-input placeholder="" v-model="ruleForm.fkcoursesRatio" size="small" :disabled="seeTH">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
                <p>
                  计算公式:国家补贴标准*比例*人数*（（总课时-公共课）/总课时）
                </p>
              </el-form-item>
              <el-form-item prop="fkchargePerson" class="form-item" v-if="
                ruleForm.contractType == '20' &&
                ruleForm.settleStandardType == '20'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">每人收费:</span>
                    <el-input placeholder="" v-model="ruleForm.fkchargePerson" size="small" :disabled="seeTH">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:每人收费*人数</p>
              </el-form-item>
              <el-form-item prop="fkfixedCost" class="form-item" v-if="
                ruleForm.contractType == '20' &&
                ruleForm.settleStandardType == '30'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">固定费用:</span>
                    <el-input placeholder="" v-model="ruleForm.fkfixedCost" size="small" :disabled="seeTH">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
              <!-- 付款合同 -- 按课时 -->
              <el-form-item prop="fkCoursesPersonHour" class="form-item" v-if="
                ruleForm.contractType == '20' &&
                ruleForm.settleStandardType == '50'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 5rem">每人每课时:</span>
                    <el-input placeholder="" v-model="ruleForm.fkCoursesPersonHour" size="small" :disabled="seeTH">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:每人收费*人数*课时数</p>
              </el-form-item>
              <!-- 付款合同 -- 按实收比例 -->
              <el-form-item prop="fkCoursesSSRatio" class="form-item" v-if="
                ruleForm.contractType == '20' &&
                ruleForm.settleStandardType == '60'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">课程比例:</span>
                    <el-input placeholder="" v-model="ruleForm.fkCoursesSSRatio" size="small" :disabled="seeTH">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:实收金额*比例</p>
              </el-form-item>
              <!-- 代理合同 --比例收费 -->
              <!-- <el-form-item prop="agentRatio" class="form-item" v-if="
                ruleForm.contractType == '40' &&
                ruleForm.settleStandardType == '10'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">比例:</span>
                    <el-input placeholder="" v-model="ruleForm.agentRatio" size="small" :disabled="seeTH">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:应收金额合计*比例</p>
              </el-form-item>
              <el-form-item prop="platformMoney" class="form-item" v-if="
                ruleForm.contractType == '40' &&
                ruleForm.settleStandardType == '20'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">每人收费:</span>
                    <el-input placeholder="" v-model="ruleForm.platformMoney" size="small" :disabled="seeTH">
                      <template slot="prepend">除去</template>
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>注:平台收取*元/人，其余金额均付给代理商</p>
              </el-form-item>
              <el-form-item prop="agentMoney" class="form-item" v-if="
                ruleForm.contractType == '40' &&
                ruleForm.settleStandardType == '30'
              ">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 4rem">每人收费:</span>
                    <el-input placeholder="" v-model="ruleForm.agentMoney" size="small" :disabled="seeTH">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>注:代理商按*元/人收费</p>
                <p>计算公式:人数*每人收费</p>
              </el-form-item> -->
              <el-form-item prop="publicCourses" class="form-item" label="公共课是否收费" v-if="
                ruleForm.contractType != '40'
              ">
                <el-radio-group v-model="ruleForm.publicCourses" :disabled="seeTH">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
                <p>如果公共课不收费，计算时需要减去公共课部分的费用。</p>
              </el-form-item>
              <el-form-item prop="chargesSettlement" class="form-item" label="收费标准及结算方式">
                <el-input placeholder="" v-model="ruleForm.chargesSettlement" size="small" maxlength="1000"
                  show-word-limit type="textarea" :disabled="seeTH">
                </el-input>
                <p>展示在结算单收费标准那一栏，并且支持修改。</p>
              </el-form-item>
              <el-form-item prop="payTaxPoint" class="form-item" label="付款税点"
                v-if="ruleForm.contractType == '20' || ruleForm.contractType == '40'">
                <el-input-number placeholder="" v-model="ruleForm.payTaxPoint" size="small" :disabled="seeTH" :step="1"
                  :precision="0" :min="0" :max="100" :controls="false" selectionStart>
                </el-input-number>&nbsp;%
              </el-form-item>

              <el-form-item prop="contractsealState" class="form-item" label="合同盖章">
                <el-radio-group v-model="ruleForm.contractsealState" :disabled="seeTH" @change="contractsealStateChange"
                  :key="radioKey">
                  <el-radio :label="item.value" v-for="(item, index) in ContractList" :key="index"
                    @click.native.prevent="changeRadio(item.value)">{{ item.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="contractsealDate" class="form-item" label="盖章日期"
                v-if="ruleForm.contractsealState == '10'">
                <el-date-picker v-model="ruleForm.contractsealDate" type="date" size="small" clearable
                  placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :disabled="seeTH">
                </el-date-picker>
              </el-form-item>
              <el-form-item prop="contractPurpose" class="form-item" label="合同用途">
                <el-radio-group v-model="ruleForm.contractPurpose" :disabled="seeTH">
                  <el-radio v-for="item in contractPurposeList" :key="item.value" :label="item.value">
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="contractForm" class="form-item" label="合同形式">
                <el-radio-group v-model="ruleForm.contractForm" @change="contractFormChange" :disabled="seeTH">
                  <el-radio v-for="item in contractFormList" :key="item.value" :label="item.value">
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 合同是否收回 -->
              <el-form-item prop="isTakeBack" class="form-item" label="合同是否收回">
                <el-radio-group v-model="ruleForm.isTakeBack" :disabled="seeTH">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 合同收回时间 -->
              <el-form-item prop="takeBackDate" v-if="ruleForm.isTakeBack" key="takeBackDate" label="合同收回时间">
                <el-date-picker style="width:200px;" size="small" v-model="ruleForm.takeBackDate" type="date" clearable
                  value-format="yyyy-MM-dd" placeholder="请选择合同收回日期" :disabled="seeTH">
                </el-date-picker>
              </el-form-item>

              <el-form-item prop="fileArr" class="form-item" label="上传附件" :rules="[
                {
                  required: isApproval ? false : true,
                  message: '请上传附件',
                  trigger: ['change'],
                },
              ]">
                <div style="display: flex; flex-direction: column">
                  <span style="color: #f46173" v-for="(item, index) in ruleForm.fileArr" :key="index">
                    {{ item.fileName }}
                    <a @click="seeExl(item.assistType)" style="color: #5bb5ff">查看</a>
                    <a @click="reomveExl(index)" style="color: #5bb5ff; margin-left: 15px"
                      v-show="!seeTH && addAgreement != 'look'">删除</a>
                  </span>
                </div>
                <el-upload v-model="ruleForm.fileName" class="upload-demo upload-btns" :action="actionUrl"
                  :on-error="handleError" :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false"
                  :auto-upload="false" :disabled="seeTH">
                  <el-button style="height:40px;" size="small" class="bgc-bv"
                    :disabled="seeTH || this.$route.query.stu == 'look'">选择合同</el-button>
                </el-upload>
              </el-form-item>
              <!-- 新增合同(须审批) -->
              <template v-if="isApproval">
                <el-form-item prop="isSign" class="form-item" label="是否申请电子章">
                  <el-radio-group v-model="ruleForm.isSign" :disabled="seeTH"
                    @change="changeIsElectronicSealForApplication">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="ruleForm.isSign">
                  <el-form-item prop="fileArr2" class="form-item" label="上传盖章的协议">
                    <div style="display: flex; flex-direction: column">
                      <span style="color: #f46173" v-for="(item, index) in ruleForm.fileArr2" :key="index">
                        {{ item.fileName }}
                        <a @click="seeExl(item.fileUrl)" style="color: #5bb5ff">查看</a>
                        <a @click="reomveExl2(index)" style="color: #5bb5ff; margin-left: 15px"
                          v-show="!seeTH && addAgreement != 'look'">删除</a>
                      </span>
                    </div>
                    <el-upload v-model="ruleForm.fileName" ref="fileArr2" class="upload-demo upload-btns"
                      :action="actionUrl" :on-error="handleError" :on-success="handleSuccess" :on-change="uploadChange2"
                      :show-file-list="false" :auto-upload="false" :disabled="seeTH">
                      <el-button style="height:40px;" size="small" class="bgc-bv"
                        :disabled="seeTH || this.$route.query.stu == 'look'">选择文件</el-button>
                    </el-upload>
                  </el-form-item>
                  <el-form-item prop="modelType" class="form-item" label="选择章">
                    <el-radio-group v-model="ruleForm.modelType" :disabled="seeTH">
                      <el-radio label="1">北京晟融公章</el-radio>
                      <el-radio label="2">北京晟融合同章</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item prop="keywordName" class="form-item" label="关键字">
                    <el-radio-group v-model="ruleForm.keywordName" :disabled="seeTH">
                      <el-radio label="1">北京晟融</el-radio>
                      <el-radio label="2">公章</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item prop="keywordPosition" class="form-item" label="盖章位置">
                    <el-radio-group v-model="ruleForm.keywordPosition" :disabled="seeTH">
                      <el-radio label="1">第一页</el-radio>
                      <el-radio label="3">每一页</el-radio>
                      <el-radio label="2">最后一页</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item prop="perforationPosition" class="form-item" label="是否加盖骑缝">
                    <el-radio-group v-model="ruleForm.perforationPosition" @click.native="onRadioChange($event)"
                      :disabled="seeTH">
                      <el-radio label="1">纵向</el-radio>
                      <el-radio label="2">横向</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item prop="perforationPosition" class="form-item" label="骑缝位置"
                    v-if="ruleForm.perforationPosition">
                    <el-radio-group v-model="ruleForm.pageSealPosition" :disabled="seeTH">
                      <el-radio label="1">上</el-radio>
                      <el-radio label="2">中</el-radio>
                      <el-radio label="3">下</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </template>
              </template>
              <el-form-item prop="remark" class="form-item" label="备注">
                <el-input placeholder="" v-model="ruleForm.remark" size="small" maxlength="500" show-word-limit
                  type="textarea" :disabled="seeTH">
                </el-input>
              </el-form-item>

              <!-- 新增合同(须审批) -->
              <template v-if="isApproval">
                <el-form-item label="是否需要第二级审批" prop="isApprove">
                  <el-radio-group v-model="ruleForm.isApprove" :disabled="seeTH">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="第一审批人" prop="reviewerId" class="form-item">
                  <el-select placeholder="请选择第一审批人" clearable size="small" v-model="ruleForm.reviewerId"
                    @change="contractChange" :disabled="seeTH">
                    <el-option v-for="item in ReviewerList" :key="item.adminId" :label="item.fullname"
                      :value="item.adminId"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="第二审批人" prop="approverId" class="form-item" v-if="ruleForm.isApprove != false">
                  <el-select placeholder="请选择第二审批人" clearable size="small" v-model="ruleForm.approverId"
                    @change="contractChange" :disabled="seeTH">
                    <el-option v-for="item in ReviewerList" :key="item.adminId" :label="item.fullname"
                      :value="item.adminId"></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <p style="margin-left: 80px;color: #f00" v-if="ruleForm.contractType == '10'">注：上海要求单价低于100时，直接一级审批</p>
            </el-form>
            <div class="pageForm-btns" v-if="!seeTH">
              <el-button class="bgc-bv" @click="doCancel">取消</el-button>
              <el-button class="bgc-bv" v-if="$route.query.stu != 'look'" @click="submit('ruleForm')">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 对话框区域 用于编辑和添加 -->
    <el-dialog @closed="clearForm" :title="dialogTitle" :visible.sync="dialogVisible" width="560px">
      <el-form size="small" :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="机构名称" prop="compName">
          <el-select style="width: 420px" size="small" v-model="ruleForm2.compId" disabled value-key="compId" remote
            :remote-method="getCompanyList" filterable clearable placeholder="请至少输入两个字搜索">
            <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
              :value="item.compId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择合同" prop="contractId">
          <div style="display: flex; align-items: center;justify-content: center;">
            <el-select style="width: 420px" size="small" v-model="ruleForm2.contractId" value-key="contractId" clearable
              placeholder="请选择合同" @change="contractDialogChange">
              <el-option v-for="item in contractList2" :key="item.contractId" :label="item.contractName"
                :value="item.contractId">{{ item.contractName }}({{ item.startDate }}-{{ item.endDate }})</el-option>
            </el-select>
            <el-button style="margin-left: 10px;" type="text" @click="previewTheContract(ruleForm2.contractId)"
              size="small">预览合同</el-button>
          </div>
        </el-form-item>
        <!-- <el-form-item label="结算方式" prop="settleStyle">
          <el-select v-model="ruleForm2.settleStyle" placeholder="请选择结算方式" size="small" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算标准" prop="settleStandard">
          <el-input style="width: 200px" v-if="ruleForm2.settleStyle == '10'" v-model="ruleForm2.settleStandard" placeholder="请输入结算标准" size="small" @input="filter">
            <template slot="append">%</template>
          </el-input>

          <el-input v-else-if="
            ruleForm2.settleStyle == '40' || ruleForm2.settleStyle == '70'
          " style="width: 200px" v-model="ruleForm2.settleStandard" placeholder="请输入结算标准" size="small" @input="filter"><template slot="append">元/人/课时</template></el-input>

          <el-input style="width: 200px" v-else v-model="ruleForm2.settleStandard" placeholder="请输入结算标准" size="small" @input="filter">
            <template slot="append">元/人</template>
          </el-input>
        </el-form-item> -->

        <el-form-item prop="paymentMethod" label="付费形式">
          <el-select v-model="ruleForm2.paymentMethod" clearable placeholder="请选择">
            <el-option v-for="item in paymentOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-date-picker
            size="small"
            v-model="ruleForm.datePicker"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="dateChange"
          >
          </el-date-picker> -->
        </el-form-item>
        <el-form-item prop="datePicker" label="有效时间">
          <el-date-picker size="small" v-model="ruleForm2.datePicker" type="daterange" align="right" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            @change="dateChange">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="background: #5c6be8; color: white;border-radius:20px;" @click="cancel">取 消</el-button>
        <el-button style="background: #5c6be8; color: white;border-radius:20px;" type="primary" @click="submit2">确
          定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="预览" :visible.sync="dialogCert" width="50%" top="2%" center :modal="false"
      :before-close="closeDownloadCert">
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
    <contracManagmentTarget ref="contracManagmentTarget" @eventBus="courseTableMoreBack" />
    <el-dialog title="新增代理商" :visible.sync="dialogVis" width="50%" top="2%" center :before-close="closeDownloadCert">
      <agentAdd :contract="contract" @contractBack="contractBack" />
    </el-dialog>
    <el-dialog title="新增机构" :visible.sync="dialogCompany" width="50%" top="2%" center>
      <div class="form-box">
        <el-form ref="ruleFormCompany" label-width="8rem" class="form" :rules="ruleFormCompanyRules"
          :model="ruleFormCompany">
          <el-form-item label="单位名称" prop="UnitName">
            <el-input v-model="ruleFormCompany.UnitName" size="small" maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="单位类型" prop="compType">
            <el-select v-model="ruleFormCompany.compType" clearable placeholder="请选择单位类型" size="small">
              <el-option v-for="item in companyList" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="社会信用代码" prop="creditCode">
            <el-input v-model="ruleFormCompany.creditCode" size="small"></el-input>
          </el-form-item>
          <el-form-item label="所属行政区划" prop="areaId">
            <el-cascader clearable filterable v-model="ruleFormCompany.areaId" :options="areatreeList"
              :props="propsarea" size="small"></el-cascader>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-select v-model="ruleFormCompany.industry" clearable filterable placeholder="请选择" size="small">
              <el-option size="small" v-for="item in industryList" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" prop="compUser" class="form-item">
            <el-input size="small" v-model="ruleFormCompany.compUser" type="text" maxlength="10" show-word-limit
              placeholder="请输入联系人" clearable :disabled="seeTH" />
          </el-form-item>
          <el-form-item label="联系方式" prop="compPhone" class="form-item">
            <el-input size="small" v-model="ruleFormCompany.compPhone" type="text" placeholder="请输入联系方式" clearable
              :disabled="seeTH" />
          </el-form-item>
          <el-form-item label="合同对接业务员" prop="marketId" class="form-item">
            <el-select size="small" v-model="ruleFormCompany.marketId" clearable filterable :disabled="seeTH"
              @change="saleChange" placeholder="请选择对接业务员">
              <el-option v-for="item in salesmanList" :key="item.saleId" :label="item.saleName"
                :value="item.saleId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn-box flexcc">
        <el-button @click="doCancelCompany" class="bgc-bv">取 消</el-button>
        <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog title="预览合同" :visible.sync="previewTheContractState" width="950px" top="2%" center>
      <contractManagementAdd :seeTH="true" :key="ruleForm2.contractId" :seeContractId="ruleForm2.contractId"
        v-if="previewTheContractState" />
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { console } from "../../utils/helper";
import contracManagmentTarget from "@/views/financialSettlement/contracManagmentTarget";
import agentAdd from "@/views/agent/agentAdd";
import moment from "moment";
export default {
  name: "contractManagementAdd",
  components: {
    contracManagmentTarget,
    agentAdd,
  },
  // 是否可编辑，合同id，是否需要审批
  props: ["seeTH", "seeContractId", "isApprovalTh"],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系人电话"));
      } else {
        callback();
      }
    };
    var creditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入社会信用代码"));
      } else if (
        value &&
        !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g.test(
          value
        )
      ) {
        callback(new Error("请输入正确的社会信用代码"));
      } else {
        callback();
      }
    };
    var validateSettleStandard = (_, value, callback) => {
      // 按比例结算时的正则
      const reg = /^100$|^(\d|[1-9]\d)(\.\d+)*$/;
      value = value.toString();
      if (value.split(".").length > 2 || value.indexOf(".") === 0) return callback("请输入正确的结算标准");
      if (this.ruleForm2.settleStyle === "10") {
        if (reg.test(value)) return callback();
        callback("比例结算不能超过100%");
      } else {
        const zs = value.split(".")[0];
        if (zs.length > 7) return callback("整数部分长度不能超过7");
        callback();
      }
    };
    return {
      contract: "contract",
      CompanyList: [],
      CompanyListPop: [],
      CompanyAgentList: [],
      /* 合同类型 */

      contractTypeList: [
        {
          value: "10",
          label: "收款合同",
        },
        {
          value: "20",
          label: "付款合同",
        },
        {
          value: "30",
          label: "备案合同",
        },
        {
          value: "40",
          label: "代理合同",
        },
      ],
      agentTypeList: [
        {
          value: "10",
          label: "个人代理",
        },
        {
          value: "20",
          label: "企业代理",
        },
      ],
      // 单位类型下拉数据
      companyList: [],
      contractList2: [], //代理商合同
      ruleForm: {
        contractForm: "10",//合同形式
        contractPurpose: "10",//合同用途
        contractType: "10",
        collectionTimeAgreement: "30",
        settlementMethod: "50",
        settleStandardType: "10",
        agencyType: "",
        agencyId: "",
        charge: "10",
        fileArr: [],
        signDate: "",
        technologyCharge: "",
        coursesCharge: "",
        technologyChargePerson: "",
        coursesChargePerson: "",
        technologyRatio: "",
        coursesRatio: "",
        fixedCost: "",
        ratio: "",
        chargePerson: "",
        chargeUnPerson: "",
        chargeHourPerson: "",
        fkchargePerson: "",
        fkfixedCost: "",
        fkcoursesRatio: "",
        fkCoursesPersonHour: "",
        fkCoursesSSRatio: "",
        contractId: "",
        saleId: "",
        customerNature: "10",
        areaId: '',
        trainTypeIdRange: [],
        agencyId2: "",
        decisionMaker: "",
        salesman: "",
        contractsealState: "",
        decisionMakerMobile: "",
        radioKey: false,
        payTaxPoint: '6',

        contractsealDate: "",

        isApprove: true,//是否需要二级审批
        reviewerId: "", //第一审批人
        approverId: "", //第二审批人
        isTakeBack: false,// 合同是否收回
        takeBackDate: moment(new Date()).format("yyyy-MM-DD"),

        isSign: false,// 是否申请电子章
        fileArr2: [], // 上传盖章的协议
        modelType: '1',// 选择章:1北京晟融公章 2北京晟融合同章
        keywordName: '1',// 关键字:1北京晟融 2公章
        keywordPosition: '1',// 盖章位置:1第一页 2最后一页 3每页一个
        perforationPosition: '',// 骑缝章方向:1纵向 2横向
        pageSealPosition: '2',// 骑缝位置:1上 2中 3下
        // 代理商结算标准对应字段 start
        // 10 按比例付费
        proportion: '',
        // 20//按人数 扣费
        peopleNumfeeDeduction: '',
        // 30//按人数 付费
        peopleNumPay: '',
        // 40//按课时 付费
        classHourPay: '',
        // 50 按付款时间扣费
        prepayment: '',//预付费
        afterPaying: '',//后付费
        // 60 按人次阶梯扣费
        personTimeList: [
          {
            startNum: '1',
            endNum: undefined,
            price: '',
          }
        ],
        // 70//按课时扣费
        classHourfeeDeduction: '',
        // 80 按补贴人数扣费
        subsidiesNumfeeDeduction: '',//已领补贴
        unSubsidiesNumfeeDeduction: '',//未领补贴
        // 代理商结算标准对应字段 end
      },
      rules: {
        contractType: [
          { required: true, message: "请选择合同类型", trigger: "change" },
        ],
        contractName: [
          { required: true, message: "请输入合同名称", trigger: "blur" },
        ],
        agencyType: [
          { required: true, message: "请选择代理类型", trigger: "change" },
        ],
        agencyId: [
          {
            required: true,
            message: "请选择代理商名称或个人代理商联系人姓名",
            trigger: "change",
          },
        ],
        signCompId: [
          { required: true, message: "请选择机构", trigger: "change" },
        ],
        compIds: [
          { required: true, message: "请选择实际培训单位", trigger: "blur" },
        ],
        signDate: [
          { required: true, message: "请选择签订日期", trigger: "change" },
        ],
        termValidity: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        partyAName: [
          { required: true, message: "请输入甲方联系人", trigger: "blur" },
        ],
        partyAMobile: [{ required: true, validator: Phone, trigger: "blur" }],
        partyBName: [
          { required: true, message: "请输入乙方联系人", trigger: "blur" },
        ],
        partyBMobile: [{ required: true, validator: Phone, trigger: "blur" }],
        partyBBank: [
          { required: true, message: "请输入乙方开户银行", trigger: "blur" },
        ],
        partyBAccount: [
          { required: true, message: "请输入乙方银行账户", trigger: "blur" },
        ],
        signatory: [
          { required: true, message: "请输入签字人", trigger: "blur" },
        ],
        saleId: [
          { required: true, message: "请选择合同对接业务员", trigger: "change" },
        ],
        customerNature: [
          { required: true, message: "请选择客户性质", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],

        trainTypeIdRange: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        collectionTimeAgreement: [
          { required: true, message: "请选择收款时间约定", trigger: "change" },
        ],
        settlementMethod: [
          { required: true, message: "请选择结算方式", trigger: "change" },
        ],
        settleStandardType: [
          { required: true, message: "请选择结算标准", trigger: "change" },
        ],
        technologyRatio: [
          { required: true, message: "请输入技术比例", trigger: "blur" },
        ],
        coursesRatio: [
          { required: true, message: "请输入课程比例", trigger: "blur" },
        ],
        technologyCharge: [
          { required: true, message: "请输入技术每人收费", trigger: "blur" },
        ],
        coursesCharge: [
          { required: true, message: "请输入课程每人收费", trigger: "blur" },
        ],
        technologyChargePerson: [
          {
            required: true,
            message: "请输入技术每人/课时收费",
            trigger: "blur",
          },
        ],
        coursesChargePerson: [
          {
            required: true,
            message: "请输入课程每人/课时收费",
            trigger: "blur",
          },
        ],
        ratio: [
          {
            required: true,
            message: "请输入比例",
            trigger: "blur",
          },
        ],
        chargePerson: [
          {
            required: true,
            message: "请输入领取补贴每人收费",
            trigger: "blur",
          },
        ],
        chargeHourPerson: [
          {
            required: true,
            message: "请输入每人/课时收费",
            trigger: "blur",
          },
        ],
        fixedCost: [
          { required: true, message: "请输入固定费用", trigger: "blur" },
        ],
        fkcoursesRatio: [
          { required: true, message: "请输入课程比例", trigger: "blur" },
        ],
        fkchargePerson: [
          { required: true, message: "请输入每人收费", trigger: "blur" },
        ],
        fkfixedCost: [
          { required: true, message: "请输入固定费用", trigger: "blur" },
        ],
        fkCoursesPersonHour: [
          { required: true, message: "请输入每人每课时", trigger: "blur" },
        ],
        fkCoursesSSRatio: [
          { required: true, message: "请输入课程比例", trigger: "blur" },
        ],
        agentRatio: [
          { required: true, message: "请输入比例", trigger: "blur" },
        ],
        platformMoney: [
          { required: true, message: "请输入每人收费", trigger: "blur" },
        ],
        agentMoney: [
          { required: true, message: "请输入每人收费", trigger: "blur" },
        ],
        publicCourses: [
          {
            required: true,
            message: "请选择公共课是否收费",
            trigger: "change",
          },
        ],
        chargesSettlement: [
          {
            required: true,
            message: "请输入收费标准及结算方式",
            trigger: "blur",
          },
        ],
        payTaxPoint: [
          {
            required: true,
            message: "请输入付款税点",
            trigger: "blur",
          },
        ],

        // contractsealState: [
        //   {
        //     required: true,
        //     message: "请选择合同盖章",
        //     trigger: "change",
        //   },
        // ],
        contractsealDate: [
          {
            required: true,
            message: "请选择盖章日期",
            trigger: "change",
          },
        ],
        reviewerId: [
          { required: true, message: "请选择第一审批人", trigger: "change" },
        ],
        approverId: [
          { required: true, message: "请选择第二审批人", trigger: "change" },
        ],
        takeBackDate: [
          { required: true, message: "请选择合同收回时间", trigger: "change" }
        ],
        prepayment: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        proportion: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        afterPaying: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        classHourfeeDeduction: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        subsidiesNumfeeDeduction: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        unSubsidiesNumfeeDeduction: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        peopleNumfeeDeduction: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        peopleNumPay: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        classHourPay: [
          { required: true, message: "请输入结算标准", trigger: "blur" }
        ],
        isSign: [
          {
            required: true,
            message: "请选择是否申请电子章",
            trigger: "change",
          },
        ],
        fileArr2: [
          {
            required: true,
            message: "请上传需要盖章的协议",
            trigger: "change",
          },
        ],
        modelType: [
          {
            required: true,
            message: "请选择章",
            trigger: "change",
          },
        ],
        keywordName: [
          {
            required: true,
            message: "请选择关键字",
            trigger: "change",
          },
        ],
        keywordPosition: [
          {
            required: true,
            message: "请选择盖章位置",
            trigger: "change",
          },
        ],
      },

      // 添加或修改表单的数据
      ruleForm2: {
        agencyOrganRelationId: "",
        compAddress: "",
        compId: "",
        compLegal: "",
        contractId: "",
        compName: "",
        compPhone: "",
        organEndDate: "",
        organStartDate: "",
        // settleStandard: "",
        // settleStyle: "",
        datePicker: [],
        paymentMethod: '10',
      },
      // 表单规则
      rules2: {
        settleStyle: [
          {
            required: true,
            message: "请选择结算方式",
            trigger: "blur",
          },
        ],
        contractId: [
          {
            required: true,
            message: "请选择合同",
            trigger: "blur",
          },
        ],
        settleStandard: [
          {
            required: true,
            message: "请输入结算标准",
            trigger: "blur",
          },
          {
            validator: validateSettleStandard,
            trigger: "blur",
          },
        ],
        paymentMethod: [
          {
            required: true,
            message: "请选择付费形式",
            trigger: "change",
          },
        ],
        datePicker: [
          {
            required: true,
            message: "请选择有效时间",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,

      dialogCert: false,
      contractId: "",
      targetList: [],
      dialogVis: false,
      salesmanList: [],
      ContractList: [],
      agreementType: "",
      ReviewerList: [], //审批人
      isApproval: false, //是否须审批
      queryName: "", //新增机构名称
      dialogCompany: false, //新增机构弹窗
      ruleFormCompany: {
        UnitName: "",
        creditCode: "",
        areaId: "",
        industry: "",
        compUser: "",
        compPhone: "",
        marketId: "",
        compType: ""
      },
      ruleFormCompanyRules: {
        UnitName: [
          { required: true, trigger: "blur", message: "请输入企业名称" },
        ],

        creditCode: [
          { required: true, trigger: "blur", validator: creditCode },
        ],
        areaId: [
          { required: true, trigger: "change", message: "请选择所属行业区划" },
        ],
        industry: [
          { required: true, trigger: "change", message: "请选择所属行业" }
        ],
        compUser: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        compPhone: [{ required: true, validator: Phone, trigger: "blur" }],
        marketId: [
          { required: true, message: "请选择合同对接业务员", trigger: "change" },
        ],
        compType: [
          { required: true, message: "请选择单位类型", trigger: "change" }
        ]
      },

      areatreeList: [],
      industryList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },

      SettlementByTimeList: [],
      obj: {
        SettlementByTime: "",
      },
      // 代理商 - 下拉数据
      agencyNameList: [],
      // 结算方式
      settlementMethodList: [],
      // 收款时间约定
      collectionTimeAgreementList: [],
      // 合同形式
      contractFormList: [],
      // 合同用途 
      contractPurposeList: [],
      options: [], //结算方式
      paymentOptions: [], //付费形式
      trainingTypeList: [], //培训类型
      customerNatureList: [], //客户性质
      previewTheContractState: false,//预览合同
    };
  },
  watch: {},
  created() {
    this.getCode()
    this.getOptions()
    this.gettrainingType()
    this.getCollectionTimeAgreementList()
    this.getcontractFormList()
    this.contractId = this.$route.query.contractId;
    this.addAgreement = this.$route.query.stu;
    this.stu = this.$route.query.stu;
    this.agreementId = this.$route.query.agreementId;
    this.agreementType = this.$route.query.agreementType;
    this.isApproval = (this.$route.query.isApproval || typeof this.$route.query.isApproval == 'boolean')
      ? JSON.parse(this.$route.query.isApproval)
      : this.isApprovalTh;
    this.getSalesmanList();
    this.getCodeValue();
    this.getReviewerList();
    this.getareatree();
    if (this.seeContractId || this.contractId) {
      this.getInfo(this.seeContractId || this.contractId);
    }
    if (this.addAgreement == "addAgreement") {
      this.getInfoAgreement(this.agreementId);
    }
    this.SettlementByTimeList.push({ ...this.obj });
    // 获取单位类型
    this.getCompTypeList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() { },
  watch: {

  },
  methods: {
    // 预览合同
    previewTheContract(contractId) {
      if (!contractId) {
        this.$message.warning('请选择合同！')
        return
      }
      this.previewTheContractState = true;
    },
    // 比较两数大小
    compareTheSize(index, type) {
      if (type == 'min') {
        if (this.ruleForm.personTimeList[index].startNum && this.ruleForm.personTimeList[index].endNum) {
          if (this.ruleForm.personTimeList[index].startNum >= this.ruleForm.personTimeList[index].endNum) {
            if (type == 'min') {
              this.ruleForm.personTimeList[index].startNum = 1
              this.$message.error('输入起始值不能大于等于结束值！')
            }
            if (type == 'max') {
              this.ruleForm.personTimeList[index].endNum = 1
              this.$message.error('输入结束值不能小于等于起始值！')
            }
          }
        }
      }
    },
    // 代理商 - 按人次阶梯扣费 - 新增
    addItem() {
      this.ruleForm.personTimeList.push({
        startNum: undefined,
        endNum: undefined,
        price: '',
      })
    },
    // 代理商 - 按人次阶梯扣费 - 删除
    removeItem(index) {
      if (index === 0) return
      this.ruleForm.personTimeList.splice(index, 1)
    },
    // 是否加盖骑缝 - 单选的时候  可以在取消
    onRadioChange(e) {
      if (e.target.tagName === "INPUT") {
        if (this.ruleForm.perforationPosition === "") {
          this.ruleForm.perforationPosition = "1";
        } else {
          this.ruleForm.perforationPosition = "";
        }
      }
    },

    contractFormChange(val) {
      if (val == 20) {//电子合同
        this.ruleForm.isTakeBack = true
      }
    },
    // 获取合同形式，合同用途字典
    getcontractFormList() {
      const list1 = this.$setDictionary("CONTRACT_FORM", "list");
      const list2 = this.$setDictionary("CONTRACT_PURPOSE", "list");
      let contractFormList = [];
      let contractPurposeList = [];
      for (const key in list1) {
        contractFormList.push({
          value: key,
          label: list1[key]
        });
      }
      for (const key in list2) {
        contractPurposeList.push({
          value: key,
          label: list2[key]
        });
      }
      this.contractFormList = contractFormList;
      this.contractPurposeList = contractPurposeList;
    },
    // 获取收款时间约定
    getCollectionTimeAgreementList() {
      const collectionTimeAgreement = this.$setDictionary("CONTRACT_COLLECTION_AGREEMENT", "list");
      let collectionTimeAgreementList = [];
      for (const key in collectionTimeAgreement) {
        collectionTimeAgreementList.push({
          value: key,
          label: collectionTimeAgreement[key]
        });
      }
      this.collectionTimeAgreementList = collectionTimeAgreementList;
      // 客户性质
      const list = this.$setDictionary("CUSTOMER_NATURE", "list");
      let customerNatureList = [];
      for (const key in list) {
        customerNatureList.push({
          value: key,
          label: list[key]
        });
      }
      this.customerNatureList = customerNatureList;

    },
    // 获取培训类型列表
    gettrainingType() {
      this.$post("/sys/category/train-type/tree", {})
        .then((res) => {
          if (res.status == 0) {
            this.trainingTypeList = res.data || []
          }
        })
        .catch(() => {
          return;
        });
    },
    //取消对话框
    cancel() {
      this.backupsRemarks = "";
      this.dialogVisible = false;
    },
    // 当日期改变时候触发
    dateChange(date) {
      const { ruleForm2 } = this;
      ruleForm2.organStartDate = date[0].replaceAll("-", "/");
      ruleForm2.organEndDate = date[1].replaceAll("-", "/");
    },
    // 对话框确定按钮
    submit2() {
      this.$refs["ruleForm2"].validate((valid) => {
        if (valid) {
          const { ruleForm2, isEdit } = this;
          ruleForm2.agencyId = this.ruleForm.agencyId2;
          if (ruleForm2.datePicker && ruleForm2.datePicker.length === 2) {
            ruleForm2.organStartDate = ruleForm2.datePicker[0]
            ruleForm2.organEndDate = ruleForm2.datePicker[1]
          }
          this.$post(
            "/agency/ma-agency-static/organ/insertOrUpdate",
            ruleForm2
          ).then((res) => {
            if (res.status == "0") {
              this.dialogVisible = false;
              this.$message({
                type: "success",
                message: "绑定成功",
              });
            }
          });
        }
      });
    },
    // 获取码值
    getOptions() {
      const agencySettleStyle = this.$setDictionary(
        "AGENCY_SETTLE_STYLE",
        "list"
      );
      for (const key in agencySettleStyle) {
        this.options.push({
          value: key,
          label: agencySettleStyle[key],
        });
      }
      const agencyPaymentMethod = this.$setDictionary(
        "AGENCY_PAYMENT_METHOD",
        "list"
      );
      for (const key in agencyPaymentMethod) {
        this.paymentOptions.push({
          value: key,
          label: agencyPaymentMethod[key],
        });
      }
    },
    // 绑定机构
    bindJG() {
      this.dialogTitle = '绑定机构';
      this.ruleForm2 = {
        agencyOrganRelationId: "",
        compAddress: "",
        compId: this.ruleForm.signCompId,
        compLegal: "",
        contractId: "",
        compName: "",
        compPhone: "",
        organEndDate: "",
        organStartDate: "",
        // settleStandard: "",
        // settleStyle: "",
        datePicker: [],
        paymentMethod: '10',
      }
      this.dialogVisible = true;
      this.getContractList()
      // 编辑
      // this.$post("/agency/ma-agency-static/organ/relationInfo", {
      //   agencyOrganRelationId:this.ruleForm.agencyId2,
      // }).then((res) => {
      //   // res.data["datePicker"] = [
      //   //   res.data.organStartDate.replaceAll("/", "-"),
      //   //   res.data.organEndDate.replaceAll("/", "-"),
      //   // ];
      //   // this.ruleForm = res.data;
      //   this.dialogVisible = true;
      // });
    },
    // 切换合同
    contractDialogChange(val) {
      if (!val) {
        return
      }
      const contractObject = this.contractList2.find(e => e.contractId === val)
      this.ruleForm2.datePicker = [contractObject.startDate.replaceAll("/", "-"), contractObject.endDate.replaceAll("/", "-")]
    },
    // 获取码值数据
    getCode() {
      // 获取结算方式
      const settlementMethod = this.$setDictionary("CONTRACT_SETTLEMENT_METHOD", "list");
      for (const key in settlementMethod) {
        this.settlementMethodList.push({
          value: key,
          label: settlementMethod[key],
        });
      }

      // 获取结算方式
      const collectionTimeAgreement = this.$setDictionary("CONTRACT_SETTLEMENT_METHOD", "list");
      for (const key in collectionTimeAgreement) {
        this.collectionTimeAgreementList.push({
          value: key,
          label: collectionTimeAgreement[key],
        });
      }
    },
    // 合同盖章因为是非必填可以取消选中
    changeRadio(val) {
      this.ruleForm.contractsealState = this.ruleForm.contractsealState == val ? '' : val
      this.radioKey = !this.radioKey
    },
    // 新增 -- 按次结算
    certificate() {
      this.SettlementByTimeList.push({ ...this.obj });
    },
    delCertificate(ind) {
      // if (this.SettlementByTimeList[ind].teacherId) {
      //   this.delIds.push({
      //     teacherQualificationId: this.addlist[ind].teacherQualificationId,
      //   });
      // }
      this.SettlementByTimeList.splice(ind, 1);
    },
    contractChange(val) {
      this.$refs["ruleForm"].clearValidate();
      if (val == "10" || val == "30") {
        this.ruleForm.fkchargePerson = "";
        this.ruleForm.fkcoursesRatio = "";
        this.ruleForm.fkfixedCost = "";
        this.ruleForm.agencyId2 = "";

        this.ruleForm.collectionTimeAgreement = "30";
        this.ruleForm.settlementMethod = "50";

      }
      if (val == "20") {
        this.ruleForm.technologyCharge = "";
        this.ruleForm.coursesCharge = "";
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.technologyChargePerson = "";
        this.ruleForm.coursesChargePerson = "";
        this.ruleForm.chargePerson = "";
        this.ruleForm.chargeUnPerson = "";
        this.ruleForm.chargeHourPerson = "";
        this.ruleForm.ratio = "";
        this.ruleForm.fixedCost = "";
        this.ruleForm.fkCoursesPersonHour = "";
        this.ruleForm.fkCoursesSSRatio = "";
      }
      if (val == "40") {
        this.ruleForm.settleStandardType = '20'
        this.ruleForm.prepayment = "";
        this.ruleForm.afterPaying = "";
        this.ruleForm.personTimeList = [
          {
            startNum: '1',
            endNum: undefined,
            price: '',
          }
        ];
        this.ruleForm.classHourfeeDeduction = "";
        this.ruleForm.subsidiesNumfeeDeduction = "";
        this.ruleForm.unSubsidiesNumfeeDeduction = "";
        this.ruleForm.peopleNumfeeDeduction = "";
        this.ruleForm.peopleNumPay = "";
        this.ruleForm.classHourPay = "";
        this.$refs["ruleForm"].clearValidate("agencyType");
        this.$refs["ruleForm"].clearValidate("agencyId");
      }
    },
    settleStandardTypeChange(val) {
      this.$refs["ruleForm"].clearValidate("technologyRatio");
      this.$refs["ruleForm"].clearValidate("coursesRatio");
      this.$refs["ruleForm"].clearValidate("technologyCharge");
      this.$refs["ruleForm"].clearValidate("coursesCharge");
      this.$refs["ruleForm"].clearValidate("technologyChargePerson");
      this.$refs["ruleForm"].clearValidate("coursesChargePerson");
      this.$refs["ruleForm"].clearValidate("ratio");
      this.$refs["ruleForm"].clearValidate("chargePerson");
      this.$refs["ruleForm"].clearValidate("chargeUnPerson");
      this.$refs["ruleForm"].clearValidate("chargeHourPerson");
      this.$refs["ruleForm"].clearValidate("fixedCost");
      this.$refs["ruleForm"].clearValidate("fkfixedCost");
      this.$refs["ruleForm"].clearValidate("fkCoursesPersonHour");
      this.$refs["ruleForm"].clearValidate("fkCoursesSSRatio");
      /* 分开 */
      if (val == "10" && this.ruleForm.charge == "10") {
        // settleContent.technologyRatio = this.ruleForm.technologyRatio;
        // settleContent.coursesRatio = this.ruleForm.coursesRatio;
        this.ruleForm.technologyCharge = "";
        this.ruleForm.coursesCharge = "";
        this.ruleForm.technologyChargePerson = "";
        this.ruleForm.coursesChargePerson = "";
        this.ruleForm.chargePerson = "";
        this.ruleForm.chargeUnPerson = "";
        this.ruleForm.chargeHourPerson = "";
        this.ruleForm.ratio = "";
        this.ruleForm.fixedCost = "";
      }
      if (val == "10" && this.ruleForm.charge == "20") {
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.technologyChargePerson = "";
        this.ruleForm.coursesChargePerson = "";
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.chargePerson = "";
        this.ruleForm.chargeUnPerson = "";
        this.ruleForm.chargeHourPerson = "";
        this.ruleForm.ratio = "";
        this.ruleForm.fixedCost = "";
      }
      if (val == "10" && this.ruleForm.charge == "30") {
        this.ruleForm.technologyCharge = "";
        this.ruleForm.coursesCharge = "";
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.chargePerson = "";
        this.ruleForm.chargeUnPerson = "";
        this.ruleForm.chargeHourPerson = "";
        this.ruleForm.ratio = "";
        this.ruleForm.fixedCost = "";
      }
      if (val == "20" && this.ruleForm.charge == "10") {
        this.ruleForm.technologyCharge = "";
        this.ruleForm.coursesCharge = "";
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.technologyChargePerson = "";
        this.ruleForm.coursesChargePerson = "";
        this.ruleForm.chargeHourPerson = "";
        this.ruleForm.fixedCost = "";
        this.ruleForm.fkCoursesPersonHour = "";
        this.ruleForm.fkCoursesSSRatio = "";
      }
      if (val == "20" && this.ruleForm.charge == "20") {
        this.ruleForm.technologyCharge = "";
        this.ruleForm.coursesCharge = "";
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.technologyChargePerson = "";
        this.ruleForm.coursesChargePerson = "";
        this.ruleForm.chargeHourPerson = "";
        this.ruleForm.ratio = "";
        this.ruleForm.fixedCost = "";
        this.ruleForm.fkCoursesPersonHour = "";
        this.ruleForm.fkCoursesSSRatio = "";
      }
      if (val == "20" && this.ruleForm.charge == "30") {
        this.ruleForm.technologyCharge = "";
        this.ruleForm.coursesCharge = "";
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.technologyChargePerson = "";
        this.ruleForm.coursesChargePerson = "";
        this.ruleForm.chargePerson = "";
        this.ruleForm.chargeUnPerson = "";
        this.ruleForm.ratio = "";
        this.ruleForm.fixedCost = "";
        this.ruleForm.fkCoursesPersonHour = "";
        this.ruleForm.fkCoursesSSRatio = "";
      }
      if (val == "30") {
        this.ruleForm.technologyCharge = "";
        this.ruleForm.coursesCharge = "";
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.technologyChargePerson = "";
        this.ruleForm.coursesChargePerson = "";
        this.ruleForm.chargePerson = "";
        this.ruleForm.chargeUnPerson = "";
        this.ruleForm.chargeHourPerson = "";
        this.ruleForm.ratio = "";
      }
      if (val == "40") {
        this.ruleForm.technologyCharge = "";
        this.ruleForm.coursesCharge = "";
        this.ruleForm.technologyRatio = "";
        this.ruleForm.coursesRatio = "";
        this.ruleForm.technologyChargePerson = "";
        this.ruleForm.coursesChargePerson = "";
        this.ruleForm.chargePerson = "";
        this.ruleForm.chargeUnPerson = "";
        this.ruleForm.chargeHourPerson = "";
        this.ruleForm.ratio = "";
        this.ruleForm.fixedCost = "";
      }
      if (this.ruleForm.contractType == "20") {
        if (val == "10") {
          this.ruleForm.fkchargePerson = "";
          this.ruleForm.fkfixedCost = "";
          this.ruleForm.fkCoursesPersonHour = "";
          this.ruleForm.fkCoursesSSRatio = "";
        }
        if (val == "20") {
          this.ruleForm.fkcoursesRatio = "";
          this.ruleForm.fkfixedCost = "";
          this.ruleForm.fkCoursesPersonHour = "";
          this.ruleForm.fkCoursesSSRatio = "";
        }
        if (val == "30") {
          this.ruleForm.fkchargePerson = "";
          this.ruleForm.fkcoursesRatio = "";
          this.ruleForm.fkCoursesPersonHour = "";
          this.ruleForm.fkCoursesSSRatio = "";
        }
        if (val == "40") {
          this.ruleForm.fkchargePerson = "";
          this.ruleForm.fkcoursesRatio = "";
          this.ruleForm.fkfixedCost = "";
          this.ruleForm.fkCoursesPersonHour = "";
          this.ruleForm.fkCoursesSSRatio = "";
        }
        if (val == "50") {
          this.ruleForm.fkchargePerson = "";
          this.ruleForm.fkcoursesRatio = "";
          this.ruleForm.fkfixedCost = "";
          this.ruleForm.fkCoursesSSRatio = "";
        }
        if (val == "40") {
          this.ruleForm.fkchargePerson = "";
          this.ruleForm.fkcoursesRatio = "";
          this.ruleForm.fkfixedCost = "";
          this.ruleForm.fkCoursesPersonHour = "";
        }
      }
    },
    /* 机构名称 */
    getCompanyList(query) {
      this.queryName = query.trim();
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id, isNew) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
        this.$set(this.ruleForm, "signCompId", this.CompanyList[0].compId);

        this.$refs["ruleForm"].clearValidate("signCompId");
      });
      // isNew 是否是新增的机构
      if (isNew) {
        if (this.ruleForm.contractType == '10' || this.ruleForm.contractType == '30') {
          this.$set(this.ruleForm, "partyAName", this.ruleFormCompany.compUser);
          this.$set(this.ruleForm, "partyAMobile", this.ruleFormCompany.compPhone);
        } else if (this.ruleForm.contractType == '20') {
          this.$set(this.ruleForm, "partyBName", this.ruleFormCompany.compUser);
          this.$set(this.ruleForm, "partyBMobile", this.ruleFormCompany.compPhone);
        } else if (this.ruleForm.contractType == '') {
          this.$set(this.ruleForm, "partyAName", this.ruleFormCompany.compUser);
          this.$set(this.ruleForm, "partyAMobile", this.ruleFormCompany.compPhone);
        }
        // 10 收款合同   20 付款合同   30 备案合同   40 代理合同
        this.$set(this.ruleForm, "saleId", this.ruleFormCompany.marketId);

      }
    },
    getAgency(id, agencyType) {
      this.$post("/biz/new/bill/contract/agency/id", {
        agencyId: id,
        agencyType,
      }).then((res) => {
        this.CompanyAgentList = [res.data];
        this.agencyNameList = [res.data];

      });
    },
    getCompany2(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    getCompanyLists(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyListPop = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyListPop = [];
      }
    },
    /* 代理机构 */
    getCompanyAgentList(query) {
      if (query.trim().length >= 2 && this.ruleForm.agencyType) {
        this.$post("/biz/new/bill/contract/agency/company", {
          compName: query,
          agencyType: this.ruleForm.agencyType,
        })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyAgentList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyAgentList = [];
      }
    },

    // 根据关键字获取代理商名称
    getAgencyName(query) {
      this.$post("/biz/new/bill/contract/agency/company", {
        compName: query,
        agencyType: '20', // 不知道干啥的,后台默认让这么给
      })
        .then((res) => {
          ;
          if (res.status == 0) {
            this.agencyNameList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /*  合同上传*/
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";

      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.fileArr.push({
            fileName: fileName,
            fileKey: result.data.fileKey,
            assistType: result.data.fileURL,
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl(index) {
      this.ruleForm.fileArr.splice(index, 1);
      this.$forceUpdate();
    },

    /*  盖章的协议上传*/
    uploadChange2(file) {
      this.ruleForm.fileArr2 = []; // 每次上传清空，因为只能传一个
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";

      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf文件");
        return;
      }
      if (size > 5) {
        this.$message.error("文件大小不能超过5M");
        return;
      }
      let fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.fileArr2.push({
            fileName: fileName,
            fileKey: result.data.fileKey,
            fileUrl: result.data.fileURL,
          });
          this.$forceUpdate();
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
    },
    reomveExl2(index) {
      this.ruleForm.fileArr2.splice(index, 1);
      this.$forceUpdate();
    },

    /* 关联合同 */
    getTarget() {
      this.$refs.contracManagmentTarget.showPopUp();
    },
    // 批量选课件
    courseTableMoreBack(ret) {
      console.log("8888", ret);
      this.gettargetName(ret.contractName);
    },
    gettargetName(contractName) {
      this.$post("/biz/new/bill/contract/quickQueryContractRelationList", {
        contractName,
      })
        .then((ret) => {
          this.targetList = ret.data;
          this.ruleForm.contractId = this.targetList[0].contractId;
        })
        .catch((err) => {
          return;
        });
    },
    /* 合同名称 */
    getTargetList(query) {
      if (query.trim().length >= 1) {
        let parmar = {
          contractName: query,
        };
        if (this.$route.query.contractId) {
          parmar.contractId = this.$route.query.contractId;
        }
        if (this.ruleForm.targetId) {
          parmar.targetId = this.ruleForm.targetId;
        }
        this.$post(
          "/biz/new/bill/contract/quickQueryContractRelationList",
          parmar
        )
          .then((res) => {
            if (res.status == 0) {
              this.targetList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTargetList2(targetId) {
      let parmar = {};
      if (this.$route.query.contractId) {
        parmar.contractId = this.$route.query.contractId;
      }
      if (targetId) {
        parmar.targetId = targetId;
      }
      this.$post(
        "/biz/new/bill/contract/quickQueryContractRelationList",
        parmar
      )
        .then((res) => {
          if (res.status == 0) {
            this.targetList = res.data || [];
            this.ruleForm.contractId = targetId;
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 预览 */
    seeExl(fileURL) {
      console.log(fileURL)
      this.dialogCert = true;
      this.$nextTick(() => {
        pdf.embed(fileURL, "#pdf-cert");
      });
    },
    //回显数据
    getInfo(contractId) {
      this.$post("/biz/new/bill/contract/getInfo", { contractId })
        .then((ret) => {
          this.ruleForm = {
            ... this.ruleForm,
            ...ret.data,
            agencyId2: ret.data.agencyId || '',
            // 合同收回时间
            takeBackDate: ret?.data?.takeBackDate ? ret.data.takeBackDate.replaceAll("/", "-") : moment(new Date()).format("yyyy-MM-DD"),
            termValidity: [
              ret.data.startDate.replaceAll("/", "-"),
              ret.data.endDate.replaceAll("/", "-"),
            ],
            fileArr: ret.data.accessories,
            charge: JSON.parse(ret.data.settleContent).charge,
            // ...JSON.parse(ret.data.settleContent),
            signDate: ret.data.signDate.replaceAll("/", "-"),
            technologyCharge: JSON.parse(ret.data.settleContent)
              .technologyCharge,
            coursesCharge: JSON.parse(ret.data.settleContent).coursesCharge,
            technologyRatio: JSON.parse(ret.data.settleContent).technologyRatio,
            coursesRatio: JSON.parse(ret.data.settleContent).coursesRatio,
            technologyChargePerson: JSON.parse(ret.data.settleContent)
              .technologyChargePerson,
            coursesChargePerson: JSON.parse(ret.data.settleContent)
              .coursesChargePerson,
            chargePerson: JSON.parse(ret.data.settleContent).chargePerson,
            chargeUnPerson: JSON.parse(ret.data.settleContent).chargeUnPerson,
            chargeHourPerson: JSON.parse(ret.data.settleContent)
              .chargeHourPerson,
            ratio: JSON.parse(ret.data.settleContent).ratio,
            fixedCost: JSON.parse(ret.data.settleContent).fixedCost,
            fkchargePerson: JSON.parse(ret.data.settleContent).fkchargePerson,
            fkfixedCost: JSON.parse(ret.data.settleContent).fkfixedCost,
            fkcoursesRatio: JSON.parse(ret.data.settleContent).fkcoursesRatio,
            publicCourses: JSON.parse(ret.data.settleContent).publicCourses,
            fkCoursesPersonHour: JSON.parse(ret.data.settleContent)
              .fkCoursesPersonHour,
            fkCoursesSSRatio: JSON.parse(ret.data.settleContent)
              .fkCoursesSSRatio,
            targetId: ret.data.targetId,
            contractsealState: ret.data.contractsealState
              ? ret.data.contractsealState
              : "",
            contractsealDate: ret.data.contractsealDate
              ? ret.data.contractsealDate.replaceAll("/", "-")
              : "",
            areaId: ret.data.areaId,
            customerNature: ret.data.customerNature,
            // 代理商
            prepayment: JSON.parse(ret.data.settleContent).prepayment,
            afterPaying: JSON.parse(ret.data.settleContent).afterPaying,
            personTimeList: JSON.parse(ret.data.settleContent).personTimeList ? JSON.parse(ret.data.settleContent).personTimeList : [{ startNum: '1', endNum: undefined, price: '', }],
            classHourfeeDeduction: JSON.parse(ret.data.settleContent).classHourfeeDeduction,
            subsidiesNumfeeDeduction: JSON.parse(ret.data.settleContent).subsidiesNumfeeDeduction,
            unSubsidiesNumfeeDeduction: JSON.parse(ret.data.settleContent).unSubsidiesNumfeeDeduction,
            peopleNumfeeDeduction: JSON.parse(ret.data.settleContent).peopleNumfeeDeduction,
            proportion: JSON.parse(ret.data.settleContent).proportion,
            peopleNumPay: JSON.parse(ret.data.settleContent).peopleNumPay,
            classHourPay: JSON.parse(ret.data.settleContent).classHourPay,
          };

          this.ruleForm.isSign = ret.data.isSign ? true : false;
          this.ruleForm.fileArr2 = ret.data.fileKey ? [{
            fileName: ret.data.fileName,
            fileKey: ret.data.fileKey,
            fileUrl: ret.data.fileUrl,
          }] : [];

          this.ruleForm.modelType = ret.data.modelType || '1';
          this.ruleForm.keywordName = ret.data.keywordName || '1';
          this.ruleForm.keywordPosition = ret.data.keywordPosition || '1';
          this.ruleForm.perforationPosition = ret.data.perforationPosition || "";
          this.ruleForm.pageSealPosition = ret.data.pageSealPosition || "";


          this.SettlementByTimeList = JSON.parse(ret.data.settleContent).SettlementByTimeList || [{ ...this.obj }]
          if (ret.data.compIds) {
            this.ruleForm.compIds = ret.data.compIds.split(",");
          }
          if (ret.data.signCompId) {
            this.getCompany(ret.data.signCompId)
          }
          if (ret.data.agencyId && ret.data.agencyType) {
            this.getAgency(ret.data.agencyId, ret.data.agencyType);
          }

          if (ret.data.contractType == '10' && ret.data.trainTypeIdRange) {
            this.ruleForm.trainTypeIdRange = ret.data.trainTypeIdRange.split(",");
          }
          if ((ret.data.contractType == '10' || ret.data.contractType == '30') && ret.data.agencyId && !ret.data.agencyType) {
            this.getAgency(ret.data.agencyId, '20');
          }

          this.getTargetList2(ret.data.targetId);
          this.ruleForm.compIds.map((el) => {
            this.getCompany2(el);
          });
          this.$forceUpdate()
        })
        .catch((err) => {
          return;
        });
    },
    addZero(num) {
      return num < 10 ? "0" + num : num;
    },
    formatDateTime(date) {
      const time = new Date(Date.parse(date));
      const Y = time.getFullYear() + "-";
      const M = this.addZero(time.getMonth() + 1) + "-";
      const D = this.addZero(time.getDate());
      return Y + M + D;
    },
    //回显数据
    getInfoAgreement(agreementId) {
      this.$post("/biz/settlement/agreement/generateContract", { agreementId })
        .then((ret) => {
          this.ruleForm = {
            ... this.ruleForm,
            ...ret.data,
            contractType: this.agreementType,
            agencyId2: ret.data.agencyId || '',

            // termValidity: [
            //   ret.data.startDate.replaceAll("/", "-"),
            //   ret.data.endDate.replaceAll("/", "-"),
            // ],
            fileArr: ret.data.accessories || [],
            charge: JSON.parse(ret.data.settleContent).charge,
            signDate: this.formatDateTime(new Date()),
            // ...JSON.parse(ret.data.settleContent),
            technologyCharge: JSON.parse(ret.data.settleContent)
              .technologyCharge,
            coursesCharge: JSON.parse(ret.data.settleContent).coursesCharge,
            technologyRatio: JSON.parse(ret.data.settleContent).technologyRatio,
            coursesRatio: JSON.parse(ret.data.settleContent).coursesRatio,
            technologyChargePerson: JSON.parse(ret.data.settleContent)
              .technologyChargePerson,
            coursesChargePerson: JSON.parse(ret.data.settleContent)
              .coursesChargePerson,
            chargePerson: JSON.parse(ret.data.settleContent).chargePerson,
            chargeUnPerson: JSON.parse(ret.data.settleContent).chargeUnPerson,
            chargeHourPerson: JSON.parse(ret.data.settleContent)
              .chargeHourPerson,
            ratio: JSON.parse(ret.data.settleContent).ratio,
            fixedCost: JSON.parse(ret.data.settleContent).fixedCost,
            fkchargePerson: JSON.parse(ret.data.settleContent).fkchargePerson,
            fkfixedCost: JSON.parse(ret.data.settleContent).fkfixedCost,
            fkcoursesRatio: JSON.parse(ret.data.settleContent).fkcoursesRatio,
            publicCourses: JSON.parse(ret.data.settleContent).publicCourses,
            contractsealState: ret.data.contractsealState
              ? ret.data.contractsealState
              : "",
            contractsealDate: ret.data.contractsealDate
              ? ret.data.contractsealDate.replaceAll("/", "-")
              : "",
            isApprove: ret.isApprove == true ? true : ret.isApprove == false ? false : true,
            reviewerId: ret.reviewerId || "",
            approverId: ret.approverId || "",
            areaId: ret.data.areaId,
            customerNature: ret.data.customerNature,
            // 代理商
            prepayment: JSON.parse(ret.data.settleContent).prepayment,
            afterPaying: JSON.parse(ret.data.settleContent).afterPaying,
            personTimeList: JSON.parse(ret.data.settleContent).personTimeList ? JSON.parse(ret.data.settleContent).personTimeList : [{ startNum: '1', endNum: undefined, price: '', }],
            classHourfeeDeduction: JSON.parse(ret.data.settleContent).classHourfeeDeduction,
            subsidiesNumfeeDeduction: JSON.parse(ret.data.settleContent).subsidiesNumfeeDeduction,
            unSubsidiesNumfeeDeduction: JSON.parse(ret.data.settleContent).unSubsidiesNumfeeDeduction,
            peopleNumfeeDeduction: JSON.parse(ret.data.settleContent).peopleNumfeeDeduction,
            proportion: JSON.parse(ret.data.settleContent).proportion,
            peopleNumPay: JSON.parse(ret.data.settleContent).peopleNumPay,
            classHourPay: JSON.parse(ret.data.settleContent).classHourPay,
          };
          this.ruleForm.isSign = ret.data.isSign ? true : false;
          this.ruleForm.fileArr2 = ret.data.fileKey ? [{
            fileName: ret.data.fileName,
            fileKey: ret.data.fileKey,
            fileUrl: ret.data.fileUrl,
          }] : [];
          this.ruleForm.modelType = ret.data.modelType ? ret.data.modelType : '1';
          this.ruleForm.keywordName = ret.data.keywordName ? ret.data.keywordName : '1';
          this.ruleForm.keywordPosition = ret.data.keywordPosition ? ret.data.keywordPosition : '1';
          this.ruleForm.perforationPosition = ret.data.perforationPosition || "";
          this.ruleForm.pageSealPosition = ret.data.pageSealPosition || "";

          if (ret.data.compIds) {
            this.ruleForm.compIds = ret.data.compIds.split(",");
          }
          if (ret.data.signCompId) {
            this.getCompany(ret.data.signCompId)
          }
          if (ret.data.agencyId && ret.data.agencyType) {
            this.getAgency(ret.data.agencyId, ret.data.agencyType);
          }
          if ((ret.data.contractType == '10' || ret.data.contractType == '30') && ret.data.agencyId && !ret.data.agencyType) {
            this.getAgency(ret.data.agencyId, '20');
          }

          this.getTargetList2(ret.data.targetId);
          this.ruleForm.compIds.map((el) => {
            this.getCompany2(el);
          });
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    //确定
    submit(ruleForm) {
      if (this.ruleForm.contractType == '40' &&
        this.ruleForm.settleStandardType == "60" &&
        this.isCross(this.ruleForm.personTimeList)) {
        this.$message.error('人次阶梯扣费存在交叉或重叠，请检查！')
        return
      }
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let settleContent = {};
          if (this.ruleForm.contractType != "20" && this.ruleForm.contractType != "40") {
            settleContent.charge = this.ruleForm.charge;
            if (this.ruleForm.settleStandardType == "30") {
              settleContent.fixedCost = this.ruleForm.fixedCost;
            }
          }
          if (this.ruleForm.contractType != "40") {
            settleContent.publicCourses = this.ruleForm.publicCourses;
            /* 分开 */
            if (
              this.ruleForm.settleStandardType == "10" &&
              this.ruleForm.charge == "10"
            ) {
              settleContent.technologyRatio = this.ruleForm.technologyRatio;
              settleContent.coursesRatio = this.ruleForm.coursesRatio;
            }
            if (
              this.ruleForm.settleStandardType == "10" &&
              this.ruleForm.charge == "20"
            ) {
              settleContent.technologyCharge = this.ruleForm.technologyCharge;
              settleContent.coursesCharge = this.ruleForm.coursesCharge;
            }
            if (
              this.ruleForm.settleStandardType == "10" &&
              this.ruleForm.charge == "30"
            ) {
              settleContent.technologyChargePerson =
                this.ruleForm.technologyChargePerson;
              settleContent.coursesChargePerson =
                this.ruleForm.coursesChargePerson;
            }
            /* 合并 */
            if (
              this.ruleForm.settleStandardType == "20" &&
              this.ruleForm.charge == "10"
            ) {
              settleContent.ratio = this.ruleForm.ratio;
            }
            if (
              this.ruleForm.settleStandardType == "20" &&
              this.ruleForm.charge == "20"
            ) {
              settleContent.chargePerson = this.ruleForm.chargePerson;
              settleContent.chargeUnPerson = this.ruleForm.chargeUnPerson;
            }
            if (
              this.ruleForm.settleStandardType == "20" &&
              this.ruleForm.charge == "30"
            ) {
              settleContent.chargeHourPerson = this.ruleForm.chargeHourPerson;
            }
            if (
              this.ruleForm.settleStandardType == "20" &&
              this.ruleForm.charge == "40"
            ) {
              if (this.check1()) {

                settleContent.SettlementByTimeList = this.SettlementByTimeList;
              }
            }
          }

          /* 固定 */
          if (this.ruleForm.contractType != "20" && this.ruleForm.contractType != "40") {
            if (this.ruleForm.settleStandardType == "30") {
              settleContent.fixedCost = this.ruleForm.fixedCost;
            }
          }

          if (this.ruleForm.contractType == "20") {
            if (this.ruleForm.settleStandardType == "10") {
              settleContent.fkcoursesRatio = this.ruleForm.fkcoursesRatio;
            }
            if (this.ruleForm.settleStandardType == "20") {
              settleContent.fkchargePerson = this.ruleForm.fkchargePerson;
            }
            if (this.ruleForm.settleStandardType == "30") {
              settleContent.fkfixedCost = this.ruleForm.fkfixedCost;
            }
            if (this.ruleForm.settleStandardType == "50") {
              settleContent.fkCoursesPersonHour =
                this.ruleForm.fkCoursesPersonHour;
            }
            if (this.ruleForm.settleStandardType == "60") {
              settleContent.fkCoursesSSRatio = this.ruleForm.fkCoursesSSRatio;
            }
          }
          if (this.ruleForm.contractType == "40") {
            switch (this.ruleForm.settleStandardType) {
              case '10':
                // 10 按比例付费
                settleContent.proportion = this.ruleForm.proportion
                break;
              case '20':
                // 20 //按人数 扣费
                settleContent.peopleNumfeeDeduction = this.ruleForm.peopleNumfeeDeduction
                break;
              case '30':
                //按人数 付费
                settleContent.peopleNumPay = this.ruleForm.peopleNumPay
                break;
              case '40':
                ////按课时 付费
                settleContent.classHourPay = this.ruleForm.classHourPay
                break;
              case '50':
                //按付款时间扣费
                settleContent.prepayment = this.ruleForm.prepayment//预付费
                settleContent.afterPaying = this.ruleForm.afterPaying//后付费
                break;
              case '60':
                // 60 按人次阶梯扣费
                settleContent.personTimeList = this.ruleForm.personTimeList
                break;
              case '70':
                //按课时扣费
                settleContent.classHourfeeDeduction = this.ruleForm.classHourfeeDeduction
                break;
              case '80':
                //按补贴人数扣费
                settleContent.subsidiesNumfeeDeduction = this.ruleForm.subsidiesNumfeeDeduction//已领补贴
                settleContent.unSubsidiesNumfeeDeduction = this.ruleForm.unSubsidiesNumfeeDeduction//未领补贴
                break;
              default:
                break;
            }
          }
          const params = {
            contractCode: this.ruleForm.contractCode || "",
            contractName: this.ruleForm.contractName,
            contractType: this.ruleForm.contractType,
            contractForm: this.ruleForm.contractForm,
            contractPurpose: this.ruleForm.contractPurpose,
            signCompId: this.ruleForm.signCompId,
            signDate: this.ruleForm.signDate,
            startDate: this.ruleForm.termValidity[0],
            endDate: this.ruleForm.termValidity[1],
            partyAName: this.ruleForm.partyAName,
            partyAMobile: this.ruleForm.partyAMobile,
            partyBName: this.ruleForm.partyBName,
            partyBMobile: this.ruleForm.partyBMobile,
            signatory: this.ruleForm.signatory,
            salesman: this.ruleForm.salesman,
            saleId: this.ruleForm.saleId,
            settleStandardType: this.ruleForm.settleStandardType,
            chargesSettlement: this.ruleForm.chargesSettlement,
            remark: this.ruleForm.remark,
            accessories: this.ruleForm.fileArr,
            settleContent: JSON.stringify(settleContent),
            targetId: this.ruleForm.contractId,
            contractsealState: this.ruleForm.contractsealState,
            agreementId: this.agreementId,
            takeBackDate: this.ruleForm.isTakeBack ? this.ruleForm.takeBackDate : "",
            areaId: this.ruleForm.areaId,
            customerNature: this.ruleForm.customerNature,
            isApproval: this.isApproval
          };
          if (this.ruleForm.isTakeBack !== "") {
            params.isTakeBack = this.ruleForm.isTakeBack;
          }
          if (this.ruleForm.contractsealState == "10") {
            params.contractsealDate = this.ruleForm.contractsealDate;
          } else {
            params.contractsealDate = "";
          }

          if (this.ruleForm.contractType == "10") {
            params.trainTypeIdRange = this.ruleForm.trainTypeIdRange.toString();
          }
          if (this.ruleForm.contractType == "20") {
            params.partyBBank = this.ruleForm.partyBBank;
            params.partyBAccount = this.ruleForm.partyBAccount;
          }
          if (
            this.ruleForm.contractType == "10"
          ) {
            params.collectionTimeAgreement = this.ruleForm.collectionTimeAgreement;
            params.settlementMethod = this.ruleForm.settlementMethod;
          }
          if (
            this.ruleForm.contractType == "10" ||
            this.ruleForm.contractType == "30"
          ) {
            params.agencyId = this.ruleForm.agencyId2;
            params.compIds = this.ruleForm.compIds
              ? this.ruleForm.compIds.toString()
              : this.ruleForm.signCompId;
          }
          if (this.ruleForm.contractType == "20" ||
            this.ruleForm.contractType == "40"
          ) {
            params.payTaxPoint = '' + this.ruleForm.payTaxPoint
          }
          if (this.ruleForm.contractType == "40") {
            params.partyBBank = this.ruleForm.partyBBank;
            params.partyBAccount = this.ruleForm.partyBAccount;
            params.agencyType = this.ruleForm.agencyType;
            params.agencyId = this.ruleForm.agencyId;
          }
          if (this.contractId) {
            params.contractId = this.contractId;
          }
          if (this.isApproval === true) {
            if (typeof this.ruleForm.isApprove == 'boolean') {
              params.isApprove = this.ruleForm.isApprove;
            }
            if (this.ruleForm.reviewerId) {
              params.reviewerId = this.ruleForm.reviewerId;
            }
            if (this.ruleForm.approverId) {
              params.approverId = this.ruleForm.approverId;
            }
          }
          if (this.ruleForm.contractsealState) {
            params.contractsealState = this.ruleForm.contractsealState;
          }

          let checkParm = {
            contractName: this.ruleForm.contractName,
            contractType: this.ruleForm.contractType,
          };
          if (this.ruleForm.contractType == "40") {
            checkParm.agencyId = this.ruleForm.agencyId;
          } else {
            checkParm.signCompId = this.ruleForm.signCompId;
          }
          if (this.contractId) {
            checkParm.contractId = this.contractId;
          }
          params.decisionMaker = this.ruleForm.decisionMaker;
          params.decisionMakerMobile = this.ruleForm.decisionMakerMobile;

          // 是否申请电子章
          params.isSign = this.ruleForm.isSign;
          if (this.ruleForm.isSign) {
            params.fileKey = this.ruleForm.fileArr2[0].fileKey;// 上传盖章的文件key
            params.fileName = this.ruleForm.fileArr2[0].fileName; // 上传盖章的文件名
            params.modelType = this.ruleForm.modelType;// 选择章
            params.keywordName = this.ruleForm.keywordName;// 关键字
            params.keywordPosition = this.ruleForm.keywordPosition;// 盖章位置
            params.perforationPosition = this.ruleForm.perforationPosition;// 骑缝章方向
            params.pageSealPosition = this.ruleForm.pageSealPosition;// 骑缝位置
          }

          this.$post("/biz/new/bill/contract/checkContract", checkParm).then(
            (res) => {
              if (res.status == 0) {
                if (!res.data) {
                  this.$post(
                    this.contractId
                      ? "/biz/new/bill/contract/modify"
                      : "/biz/new/bill/contract/insert",
                    params
                  ).then((ret) => {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    this.doCancel();
                  });
                } else {
                  this.$confirm("合同已经存在，是否继续？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                  })
                    .then(() => {
                      this.$post(
                        this.contractId
                          ? "/biz/new/bill/contract/modify"
                          : "/biz/new/bill/contract/insert",
                        params
                      ).then((ret) => {
                        this.$message({
                          message: "保存成功",
                          type: "success",
                        });
                        this.doCancel();
                      });
                    })
                    .catch(() => {
                      this.$message({
                        type: "info",
                        message: "已取消",
                      });
                    });
                }
              }
            }
          );
        }
      });
    },
    // 获取单位类型
    getCompTypeList() {
      const companyList = this.$setDictionary("COMPANYTYPE", "list");
      let companylist = [];
      for (const key in companyList) {
        //50 为非单位类型，去掉显示
        if (key !== '50') {
          companylist.push({
            value: key,
            label: companyList[key]
          });
        }
      }
      this.companyList = companylist;
    },
    //按次结算校验
    check1() {
      const cardform = this.$refs["cardform"];
      let stu = true;
      for (let i = 0; i < cardform.length; i++) {
        cardform[i].validate((valid1) => {
          if (valid1 == false) {
            stu = false;
          }
        });
      }
      return stu;
    },
    /* 取消 */
    doCancel() {
      if (this.$route.query.stu == "look") {
        this.$router.push({
          path: "/web/financialSettlement/contractFiling",
          query: {
            refresh: true,
          },
        });
      } else {
        this.$router.push({
          path: "/web/financialSettlement/contractManagement",
          query: {
            refresh: true,
          },
        });
      }
    },
    /* 新增代理商 */
    handleCreate() {
      this.dialogVis = true;
      // this.$router.push({
      //   path: "/web/agentAdd",
      //   query: { id, stu ,contract},
      // });
    },
    contractBack(agencyId) {
      this.dialogVis = false;
      if (agencyId) {
        this.getAgency(agencyId, this.ruleForm.agencyType);
        this.ruleForm.agencyId = agencyId;
      }
    },
    getSalesmanList() {
      this.$post("/biz/new/bill/contract/findSaleManList", {}).then((ret) => {
        this.salesmanList = ret.data;
      });
    },
    saleChange(val) {
      let obj = {};
      obj = this.salesmanList.find((item) => {
        console.log(item);
        //这里的userList就是上面遍历的数据源
        return item.saleId === val; //筛选出匹配数据
      });
      this.ruleForm.salesman = obj.saleName;
      this.ruleForm.signatory = obj.saleName;
    },
    // 获取代理商合同列表
    getContractList() {
      this.$post("/biz/new/bill/contract/agentContract", { agencyId: this.ruleForm.agencyId2 }).then(
        (res) => {
          this.contractList2 = res.data || [];
        }
      );
    },
    getCodeValue() {
      const ContractList = this.$setDictionary("CONTRACT_SEAL_STATE", "list");
      for (const key in ContractList) {
        this.ContractList.push({
          value: key,
          label: ContractList[key],
        });
      }
    },
    contractsealStateChange(val) {
      if (val == "10") {
        this.ruleForm.contractsealDate = this.formatDateTime(new Date());
      } else {
        this.ruleForm.contractsealDate = "";
      }
    },
    //审批人
    getReviewerList() {
      this.$post("/biz/new/bill/contract/reviewerList", {}).then((ret) => {
        this.ReviewerList = ret.data;
      });
    },
    //新增机构
    addCompany() {
      //判断是否不存在数据，触发接口
      if (this.industryList.length < 1) {
        this.getIndustryList();
      }
      this.dialogCompany = true;
      this.ruleFormCompany.UnitName = this.queryName;
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getIndustryList() {
      const dictionaryList = this.$setDictionary("INDUSTRY", "list");
      let list = [];
      for (const key in dictionaryList) {
        list.push({
          value: key,
          label: dictionaryList[key]
        });
      }
      this.industryList = list;
    },
    doAddSave() {
      const params = {
        compName: this.ruleFormCompany.UnitName,
        compType: "20",
        compNo: this.ruleFormCompany.creditCode,
        areaId: this.ruleFormCompany.areaId,
        compIndustry: this.ruleFormCompany.industry,
        compUser: this.ruleFormCompany.compUser,
        compPhone: this.ruleFormCompany.compPhone,
        marketId: this.ruleFormCompany.marketId,
        compType: this.ruleFormCompany.compType
      };
      const el = this.$refs["ruleFormCompany"];
      el.validate((valid) => {
        if (valid) {
          this.$post("/sys/company/insert", params)
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.getCompany(res.data, true);
                this.doCancelCompany();
                // setTimeout(() => {
                // this.ruleForm.signCompId = res.data
                // }, 3000);
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 判断不能数字集合不能交叉
    isCross(list) {
      let flag = false
      if (list.length > 1) {
        list.forEach(item => {
          list.forEach(subItem => {
            if (parseInt(item.startNum) !== parseInt(subItem.startNum) || parseInt(item.endNum) !== parseInt(subItem.endNum)) {
              // 如果开始和结束 有一个不相等
              if ((parseInt(subItem.startNum) <= parseInt(item.startNum) && parseInt(item.startNum) <= parseInt(subItem.endNum)) || (parseInt(subItem.startNum) <= parseInt(item.endNum) && parseInt(item.endNum) <= parseInt(subItem.endNum))) {
                // 分别把开始和结束，放入区间内比较
                flag = true
              }
            }
          })
        })
      }
      console.log(flag);
      return flag
    },
    doCancelCompany() {
      this.dialogCompany = false;
      this.ruleFormCompany = {};
    },
  },
};
</script>
<style lang="less" scoped>
.upload-btns {
  height: 40px;

  /deep/ .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

.el-textarea__inner {
  min-height: 12rem !important;
  resize: none;
}

.pageForm-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }
}

.formContractId {
  .el-form-item__content {
    display: flex;
    align-items: center;

    .el-select {
      width: 100%;
    }
  }
}

.formSelect {
  .el-select {
    width: 100%;
  }
}

.form-item-inline {
  display: inline-block;

  .el-input {
    width: 100px;
  }
}
</style>
